import axios from "axios"
import { buildAutorizationUrl, buildBaseUrl } from "./config"
import { makeArrayToast } from "../core/utils/toasts";
import { getFieldFromJwt } from "./utils";
import moment from "moment";




export async function getRolByAuthorizationBackend(jwt) {
  try {
    const authorizationResponse = await axios.post(buildAutorizationUrl(), { jwt });
    return authorizationResponse.data.message === "Unauthorized" ?
      null : authorizationResponse.data.rol || null;
  } catch (error) {
    console.error('Error en la solicitud a la uri: ' + buildAutorizationUrl(), error);
    return null;
  }
}


export async function validateJwt(jwt) {
  const baseUrl = buildBaseUrl();
  const url = `${baseUrl}/validate`;

  try {
    const response = await axios.post(url, { jwt });
    if (response.status === 200) {
      console.log('Token validado correctamente:', response.data);
      return response.data;
    } else {
      console.error('Error al validar el token:', response.statusText);
      throw new Error('Error al validar el token');
    }
  } catch (error) {
    console.error('Error al realizar la solicitud POST:', error.message);
    throw error;
  }
}


export function getNit() {
  return getFieldFromJwt('sub');
}


export async function getCompany() {
  return await getCompanyByNit(getNit());
}



export async function getDocuments(type, params = {}) {
  const { startDate, endDate, nit, uiid } = params;
  if (!nit) {
    params.nit = getNit();
  }

  /*
    Tipos de documento esperados:

    - Anexos 1 (csv, /anexo1) -> Libro Contribuyente (pdf /libro-contribuyente)
    - Anexos 2 (csv, /anexo2) -> Libro Consumidor (pdf, /libro-consumidor)

    Los valores para `type` pueden ser: 

    - anexo:1
    - anexo:2
    - libro:contribuyente
    - libro:consumidor
    - pdf:invoice
    - json:invoice

    El parámetro 'params` puede tener:

    - startDate y endDate (para los anexos y libros)
    - nit (para pdf y libros)
    - uiid (para jsons y pdf)
  */

  const [endpoint, typo] = type.split(":");
  let url = buildBaseUrl();

  switch (endpoint) {
    case 'anexo':
      url += `/documentos-tributarios/anexo${typo}`;
      break;
    case 'libro':
      url += `/libros/libro-${typo}`;
      break;
    case 'pdf':
      url += `/documentos-tributarios/pdf/${uiid}`;
      break;
    case 'json':
      url += `/documentos-tributarios/json/${uiid}`;
      break;
    default:
      throw new Error("Tipo de documento no válido.");
  }

  /* 
    Formatear fechas con moment a 'YYYY-MM-DD' si se proporcionan
    ya que el backend lo recibe de esa manera
  */
  const queryParams = new URLSearchParams({
    nit: params.nit,
    fechaInicio: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
    fechaFin: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined
  }).toString();

  url += queryParams ? `?${queryParams}` : '';

  console.log("Esta es la URL: " + url);


  const config = {
    ...params
  };

  /* 
    Ajustar el responseType solo si es un libro por que es 
    un archivo pdf
  */
  if (endpoint === 'libro') {
    config.responseType = 'blob';
  }

  try {
    const response = await axios.get(url, config);
    console.log("Esto es el response: ", response);
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
    return error.response?.data?.error ? error.response.data.error : `Ocurrió un error al tratar de leer los ${endpoint}s ${typo}`;
  }
}


/**funcion para listar usuarios */
export async function obtenerUsuarios(Nitcompany) {
  let url = buildBaseUrl();
  let nit = Nitcompany;
  try {
    const response = await axios.get(url + '/usuarios/nit/' + nit);
    console.log('URL completa:', response);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los usuarios:', error);
    return [];
  }

};

// Función para obtener las empresas con paginación
export async function getCompanies({
  offset = 0,
  limit = 10,
  nombre_empresa,
  nit,
  sector,
  ciudad,
  pais,
  activo,
  count = false,
  execute_pagination = true,
} = {}) {
  const baseUrl = buildBaseUrl();
  let url = `${baseUrl}/companies?offset=${offset * limit}&limit=${limit}`;

  if (nombre_empresa) {
    url += `&nombre_empresa=${encodeURIComponent(nombre_empresa)}`;
  }
  if (nit) {
    url += `&nit=${encodeURIComponent(nit)}`;
  }
  if (sector) {
    url += `&sector=${encodeURIComponent(sector)}`;
  }
  if (ciudad) {
    url += `&ciudad=${encodeURIComponent(ciudad)}`;
  }
  if (pais) {
    url += `&pais=${encodeURIComponent(pais)}`;
  }
  if (activo !== undefined) {
    url += `&activo=${encodeURIComponent(activo)}`;
  }
  if (count) {
    url += `&count=true`;
  }
  if (!execute_pagination) {
    url += `&execute_pagination=false`;
  }

  try {
    console.log("esto es la url: ", url);
    const response = await axios.get(url);
    if (response.data && response.data.length > 0) {
      return response.data;
    } else {
      console.log('No se encontraron empresas.');
      if (response.data.totalRegistros !== undefined) {
        return response.data.totalRegistros;
      }
      return [];
    }
  } catch (error) {
    console.error('Error al obtener las empresas:', error);
    return [];
  }
}


export async function getLengthComapnies() {
  let baseUrl = buildBaseUrl();
  try {
    const url = baseUrl + `/companies`;

    const response = await axios.get(url);
    // Verificar si la respuesta contiene datos
    if (response.data && response.data.length > 0) {
      return response.data.length;
    } else {
      console.log('No se encontraron empresas.');
      return [];
    }
  } catch (error) {
    console.error('Error al obtener las empresas:', error);
    return [];
  }
}

export async function getCompanyByNit(nit) {
  let baseUrl = buildBaseUrl();
  try {
    const url = baseUrl + '/companies/' + nit;
    console.log('URL Compañia por Nit:', url);
    const response = await axios.get(url);
    // Verificar si la respuesta contiene datos
    if (response.data) {
      return response.data;
    } else {
      console.log('No se encontró la empresa con ese NIT.');
      return null;
    }
  } catch (error) {
    console.error('Error al obtener la empresa:', error);
    return null;
  }
}
/** Función para actualizar una empresa */
export async function actualizarEmpresa(nit, datosEmpresa) {
  const baseUrl = buildBaseUrl();
  const url = `${baseUrl}/companies/${nit}`;

  try {
    const response = await axios.put(url, datosEmpresa);
    if (response.status >= 200 && response.status < 300) {
      console.log('Empresa actualizada correctamente:', response.data);
      return makeArrayToast(response.data.message ?
        response.data.message :
        "Empresa actualizada correctamente!", 1);
    } else {
      console.error('Error al actualizar la empresa:', response.statusText);
      return makeArrayToast('Hubo un error al actualizar la empresa', 1);
    }
  } catch (error) {
    console.error('Error al realizar la solicitud PUT:', error.message);
    return error.response && error.response.data && error.response.data.message ?
      makeArrayToast(error.response.data.message, 0) :
      makeArrayToast("Error desconocido, contacte con los devs", 0);
  }
}




/**Funcion Crear empresa */
export async function createCompany(companyData) {
  const baseUrl = buildBaseUrl();
  const url = `${baseUrl}/companies`;
  try {
    const response = await axios.post(url, companyData);
    console.log("Company created successfully:", response);
    return response.status === 201 ?
      makeArrayToast(response.data.message ?
        response.data.message :
        "Empresa creada correctamente!", 1) :
      makeArrayToast('Hubo un error al crear la empresa', 1);
  } catch (error) {
    console.error('Error making POST request:', error);
    return error.response && error.response.data && error.response.data.message ?
      makeArrayToast(error.response.data.message, 0) :
      makeArrayToast("Error desconocido, contacte con los devs", 0);
  }
}

export async function actualizarEstadoEmpresa(nitEmpresa, isActive) {
  const baseUrl = buildBaseUrl();
  const url = `${baseUrl}/companies/activate/${nitEmpresa}`;

  try {
    const response = await axios.put(url, { isActive });
    if (response.status >= 200 && response.status < 300) {
      console.log('Estado de la empresa actualizado correctamente:', response.data);
      return makeArrayToast(response.data.message, 1);
    } else {
      console.error('Hubo un error al actualizar el estado de la empresa:', response.statusText);
      return makeArrayToast('Error al actualizar el estado de la empresa', 1);
    }
  } catch (error) {
    console.error('Error al realizar la solicitud PUT:', error.message);
    return error.response && error.response.data && error.response.data.message ?
      makeArrayToast(error.response.data.message, 0) :
      makeArrayToast("Error desconocido, contacte con los devs", 0);
  }
}

/**Funciones para los enpoint de USUARIOS */
/* 
  Las respuestas de cada llamada de api deben de ser respondidas
  con un formato especifico, esto para que el dispash de show notification
  las capture y las pueda printiar en la pantalla

  Las respuestas de la api pueden ser:

  1. Un array (por multiples errores)
  2. Un string de todo bien (no siempre, controlar ese caso)
  3. Un string quemado de error

  el formato que espera el dispash es solamente un menssage
  y un codigo de error (0 fallo, 1 todo bien)
*/
export async function crearUsuario(datosUsuario) {
  const baseUrl = buildBaseUrl();
  const url = `${baseUrl}/usuarios`;
  try {
    const response = await axios.post(url, datosUsuario);
    console.log("Esta es la verdadera respuesta: ", response);
    return response.status === 201 ?
      makeArrayToast(response.data.message, 1) :
      makeArrayToast('Hubo un error al crear el usuario', 1);
  } catch (error) {
    return error.response.data.message ?
      makeArrayToast(error.response.data.message, 0) :
      makeArrayToast("Error desconocido, contacte con los devs", 0);
  }
}


export function urlMenu() {
  const currentUrl = window.location.href;
  console.log(currentUrl);

  const url = currentUrl.split("/")
}

// Función para actualizar un usuario
export async function updateUser(email, nit, datosUsuario) {
  const baseUrl = buildBaseUrl();
  const url = `${baseUrl}/usuarios/${encodeURIComponent(email)}/${encodeURIComponent(nit)}`;

  console.log("Datos del usuario: ", datosUsuario);

  try {
    const response = await axios.put(url, datosUsuario);

    if (response.status >= 200 && response.status < 300) {
      console.log('Usuario actualizado correctamente:', response.data);
      return makeArrayToast(response.data.message, 1);
    } else {
      console.error('Error al actualizar el usuario:', response.statusText);
      return makeArrayToast('Error al actualizar el usuario', 1);
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Error desconocido, contacte con los devs";
    console.error('Error al realizar la solicitud PUT:', error.message);
    return makeArrayToast(errorMessage, 0);
  }
}


export async function deleteUserByUsername(email, nit) {
  const emailEncoded = encodeURIComponent(email);
  const nitEncoded = encodeURIComponent(nit);
  const url = `${buildBaseUrl()}/usuarios/${emailEncoded}/${nitEncoded}`;
  try {
    const response = await axios.delete(url);
    console.log("Response delete user: ", response);
    return response.status === 200 ?
      makeArrayToast(`Usuario ${email} eliminado exitosamente.`, 1) :
      makeArrayToast('Error al eliminar el usuario', 1)
  } catch (error) {
    console.error(`Error al realizar la solicitud DELETE: ${error.message}`);
    return error.response && error.response.data && error.response.data.message ?
      makeArrayToast(error.response.data.message, 0) :
      makeArrayToast("Error desconocido, contacte con los devs", 0);
  }
}


export async function sendEmail(uiid) {
  const uiidEncoded = encodeURIComponent(uiid);
  const url = `${buildBaseUrl()}/documentos-tributarios/email/${uiidEncoded}`;
  try {
    const response = await axios.get(url);
    console.log("Esta es la respuesta: ", response);
    return response.status === 200 ?
      makeArrayToast(response.data.message, 1) :
      makeArrayToast('Error al mandar el email', 1)
  } catch (error) {
    console.error(`Error al realizar la solicitud DELETE: ${error.message}`);
    return error.response && error.response.data && error.response.data.message ?
      makeArrayToast(error.response.data.message, 0) :
      makeArrayToast("Error desconocido, contacte con los devs", 0);
  }
}


export async function getUserByEmail(email = null) {
  const emailUri = encodeURIComponent(email ? email : getEmailFromJwt());
  const url = `${buildBaseUrl()}/usuarios/email/${emailUri}`;
  try {
    console.log("esta es la urlL: ", url);
    const response = await axios.get(url);
    if (response.data && response.data.length > 0) {
      return response.data[0];
    } else {
      console.log('No se encontro el usuario.');
      console.log(response.data);
      if (response.data.totalRegistros) {
        return response.data.totalRegistros;
      }
      return [];
    }
  } catch (error) {
    console.error('Error al obtener las facturas:', error);
    return [];
  }
}




export async function getFacturas({
  offset = 0,
  limit = 100,
  count = false,
  fecha_desde,
  fecha_hasta,
  tipo_monto_mayor_que,
  tipo_monto_menor_que,
  tipo_monto_igual_que,
  fecha_dia,
  tipo_numero_documento_receptor,
  uuid = [],
  name_client,
  execute_pagination,
  order_by = 'desc',
  tipo_factura = [],
  anulado,
  certificado,
} = {}) {
  console.log("esto es el count ANTES: ", count);
  const baseUrl = buildBaseUrl();
  const nit = getNit();

  const buildQueryString = (params) => {
    return Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== null && value !== '')
      .map(([key, value]) => {
        return Array.isArray(value) ?
          value
            .filter(val => val !== undefined && val !== null && val !== '')
            .map(val => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
            .join('&') :
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');
  };


  console.log("esto es el count DESPUES: ", count);
  const commonQueries = {
    offset: offset * limit,
    limit,
    nit,
    count: count ? 'true' : '',
    fecha_desde,
    fecha_hasta,
    tipo_monto_mayor_que,
    tipo_monto_menor_que,
    tipo_monto_igual_que,
    fecha_dia,
    tipo_numero_documento_receptor,
    name_client,
    execute_pagination,
    order_by,
    anulado,
    certificado
  };

  if (uuid.length > 0) {
    commonQueries.uuid = uuid;
  }

  if (tipo_factura.length > 0) {
    commonQueries.tipo_factura = tipo_factura;
  }

  const fetchData = async (queryParams) => {
    console.log("esto es query params ", queryParams);
    const url = `${baseUrl}/documentos-tributarios/busqueda/facturas?${buildQueryString(queryParams)}`;
    console.log("Fetching URL: (" + count + ")", url);
    try {
      const response = await axios.get(url);
      return count ? response.data.totalRegistros : (response.data || []);
    } catch (error) {
      console.error('Error fetching facturas:', error);
      return [];
    }
  };

  return await fetchData(commonQueries);
}


export function getRolByJwt() {
  return getFieldFromJwt("role") || "default";
}

export function getJwt() {
  return localStorage.getItem('token');
}

export function getEmailFromJwt() {
  return getFieldFromJwt("username");
}
