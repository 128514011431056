import React, { lazy, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { themeChange } from 'theme-change';
import CheckAuth from './app/auth';
import initializeApp from './app/init';
import publicRoutes from "./routes/publicRoutes";

const Layout = lazy(() => import('./containers/Layout'))
initializeApp();



function Inner(){
  const [token, setToken] = useState(null);
  const location = useLocation();

  useEffect(() => {
    themeChange(false);
    CheckAuth(location.pathname).then(token => {
      setToken(token);
    });
  }, [location.pathname]);

  return (
    <Routes>
      {publicRoutes.map((route, key) => (
        <Route
          key={key}
          exact={true}
          path={route.path}
          element={<route.component />}
        />
      ))}
      
      {token !== null && (
        <Route path="/app/*" element={<Layout />} />
      )}
    </Routes>
  );
}


function App() {
  return (
    <Router>
      <Inner />
    </Router>
  );
}




export default App;