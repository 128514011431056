export function hasData(value) {
    if (value === null || value === undefined) {
        return false;
    }

    if (typeof value === 'string' || Array.isArray(value)) {
        return value.length > 0;
    }

    if (value instanceof Map || value instanceof Set) {
        return value.size > 0;
    }

    if (typeof value === 'object') {
        return Object.keys(value).length > 0;
    }

    // Consider 0 as "no data" for numbers, but true for all other numbers
    if (typeof value === 'number') {
        return value !== 0;
    }

    // For booleans, only true should return true
    if (typeof value === 'boolean') {
        return value === true;
    }

    return true;
}
