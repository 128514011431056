export function getApiIp() {
    return process.env.REACT_APP_API_HOST;
}

export function getApiProtocol() {
    return process.env.REACT_APP_API_PROTOCOL;
}

export function getApiPort() {
    return process.env.REACT_APP_API_PORT;
}

export function getApiUriBase() {
    return process.env.REACT_APP_API_URI_BASE; 
}

export function getApiUriAuth() {
    return process.env.REACT_APP_API_URI_AUTH;
}

export function getApiUriData() {
    return process.env.REACT_APP_API_URI_DATA;
}

export function getNameApp(){
    return process.env.REACT_APP_NAME;
}

export function getAuthorizationUri(){
    return process.env.REACT_APP_API_URI_AUTHORIZATION;
}


export function buildBaseUrl() {
    const protocol = getApiProtocol();
    const ip = getApiIp();
    const port = getApiPort();
    const uriBase = getApiUriBase();

    if (protocol && ip && port && uriBase) {
        return `${protocol}://${ip}:${port}${uriBase}`;
    }

    return undefined;
}
 
export function buildAuthUrl() {
    const baseUrl = buildBaseUrl();
    const uriAuth = getApiUriAuth();

    if (baseUrl && uriAuth) {
        return `${baseUrl}${uriAuth}`;
    }

    return undefined;
}

export function buildDataUrl() {
    const baseUrl = buildBaseUrl();
    const uriData = getApiUriData();

    if (baseUrl && uriData) {
        return `${baseUrl}${uriData}`;
    }

    return undefined;
}


// esta ruta debe de ser: /validate
export function buildAutorizationUrl(){
    const baseUrl = buildBaseUrl();
    const uriAuthorization = getAuthorizationUri();

    if(baseUrl && uriAuthorization){
        return `${baseUrl}${uriAuthorization}`;
    }

    return undefined;
}
