export default class LoadRoles {
    constructor(...roles) {
        this.roles = {};
        roles.forEach(role => {
            this.roles[role.name] = role;
        });
    }

    get(roleName) {
        return this.roles[roleName];
    }

    getJustNameRoles() {
        return Object.keys(this.roles);
    }
}