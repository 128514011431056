import { jwtDecode } from "jwt-decode";
import { getJwt } from "./user";

export function getFieldFromJwt(fieldPath) {
    const token = getJwt();
    let fieldValue = null;

    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            const keys = fieldPath.split('.');
            fieldValue = keys.reduce((obj, key) => (obj && obj[key] !== undefined) ? obj[key] : null, decodedToken);

            if (fieldValue === null) {
                console.error(`El campo '${fieldPath}' no está presente en el token decodificado.`);
            }
        } catch (error) {
            console.error("Error al decodificar el token:", error);
            return null;
        }
    }

    return fieldValue;
}