import Router from '../core/router/app';
import { lazy } from 'react';
const Login = lazy(() => import('../pages/Login'))
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'))
const Documentation = lazy(() => import('../pages/Documentation'))
const Page404 = lazy(() => import('../pages/protected/404'));   
const router = new Router();


router.setRoute('/login', Login);
router.setRoute('/forgot-password', ForgotPassword);
router.setRoute('/documentation', Documentation);
router.setRoute('/404', Page404);


export default router.build();