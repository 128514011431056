import DefaultRoleUnauthenticated from "../core/roles/defaultRoleUnauthenticated";
import LoadRoles from "../core/roles/app";
import Rol from "../core/roles/rol";
import Router from "../core/router/app";

import routesOwner from "../routes/owner";
import routesAdmin from "../routes/admin";
import routesUser from "../routes/user";
import publicRoutes from "../routes/publicRoutes";

const routes = new Router();

const OWNER_PAGES = routes.getJustRoutes(routesOwner);
const ADMIN_PAGES = routes.getJustRoutes(routesAdmin);
const USER_PAGES = routes.getJustRoutes(routesUser);
const PUBLIC_PAGES = routes.getJustRoutes(publicRoutes);

export default new LoadRoles(
    new Rol("owner", OWNER_PAGES, "/app/owner/welcome"),
    new Rol("admin", ADMIN_PAGES, "/app/admin/companies"),
    new Rol("user", USER_PAGES, "/app/welcome"),
    new DefaultRoleUnauthenticated(PUBLIC_PAGES, "/login")
);
