import { lazy } from "react";
import Router from "../core/router/app";
const Welcome = lazy(() => import('../pages/protected/Welcome'));
const CompanySettings = lazy(() => import('../pages/protected/CompanySettings'))
const AddUser = lazy(() => import('../pages/protected/AddUser'))
const ListUsers = lazy(() => import('../pages/protected/ListUsers'))
const Filescrt = lazy(() => import('../pages/protected/File_crt'))
const Edits = lazy(() => import('../pages/protected/Edit'))

const router = new Router();


router.setPrefix('/admin');

router.setRoute("/companies", Welcome);
router.setRoute('/company', CompanySettings)
router.setRoute("/company/list", ListUsers)
router.setRoute("/company/list/user/add", AddUser)
router.setRoute("/files", Filescrt)
router.setRoute("/editors", Edits)


export default router.build(); 