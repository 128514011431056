import axios from "axios"
import { buildAuthUrl } from "./config"
import Authorization from "../core/authorization/app";
import roles from "./roles";
import { getRolByAuthorizationBackend } from "./user";



const CheckAuth = async (location) => {
  let userRole = null;
  let TOKEN = null;
  try {
    TOKEN = localStorage.getItem("token");
    axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`;

    axios.interceptors.request.use(function (config) {
      document.body.classList.add('loading-indicator');
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      document.body.classList.remove('loading-indicator');
      return response;
    }, function (error) {
      document.body.classList.remove('loading-indicator');
      return Promise.reject(error);
    });
    /* const authorizationResponse = await axios.post(buildAutorizationUrl());
    console.log(buildAutorizationUrl());
    console.log(authorizationResponse.data.rol); */

    // const userRoleTest = await getRolByAuthorizationBackend(TOKEN);
    // console.log("el rol authorizado desde el backend es: " + userRoleTest);
    /* 
      esto por el momento estara asi para que se pueda seguir desarrollando normalmente
      pero mas tarde dentra que cambiarse por:
      userRole = await getRolByAuthorizationBackend(TOKEN);
    */

    /* userRole = getRolByJwt(); */

    userRole = await getRolByAuthorizationBackend(TOKEN);
  } catch (error) {
    userRole = "default";
    console.error("Error configuring token:", error);
  }
  console.log("Rol setted: " + userRole);
  const authorization = new Authorization(roles, "token", location);
  authorization.setRolAuthorize(userRole);
  authorization.run();
  return TOKEN;
};

export default CheckAuth;


/* 
  {
    "nombreUsuario": "usuario-prueba",
    "contrasena": "12345678",
    "nit": "06142009131109"
}
*/

export async function login(email, password, nit) {
  console.log("La llamada se hace a: " + buildAuthUrl());
  return axios.post(buildAuthUrl(), {
    email,
    password,
    nit
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      throw error; // Lanza el error para que sea manejado en el catch del caller
    });
}

