import { lazy } from "react";
import Router from "../core/router/app";
const Companies = lazy(() => import('../pages/protected/Companies'));
const Welcome = lazy(() => import('../pages/protected/Welcome'));
const Company = lazy(() => import('../pages/protected/Company'))
const AddCompany = lazy(() => import('../pages/protected/AddCompany'))
const AddUser = lazy(() => import('../pages/protected/AddUser'))
const File = lazy(() => import('../pages/protected/File_crt'))
const Edit = lazy(() => import('../pages/protected/Edit'))
const router = new Router();


router.setPrefix('/owner');

router.setRoute("/welcome", Welcome);
router.setRoute("/companies", Companies);
router.setRoute("/companies/:nit", Company)
router.setRoute("/companies/add", AddCompany)
router.setRoute("/companies/:nit/user/add", AddUser)
router.setRoute("/companies/:nit/admin/add", AddUser)
router.setRoute("/companies/file", File)
router.setRoute("/companies/editors", Edit)


export default router.build(); 