export default class Router {
    prefix = null;
    routes = [];
    allRoutes = [];

    mergeRoutes(...routesBuilded) { 
        return [].concat(...routesBuilded);
    }

    setPrefix(prefix) {
        this.prefix = prefix;
    }

    setRoute(route, component) {
        this.routes.push({
            path: route, 
            component: component
        });
    }

    build() {
        if (this.prefix === null) {
            return this.routes;
        }
    
        return this.routes.map(element => ({
            path: this.prefix + element.path,
            component: element.component
        }));
    }

    getJustRoutes(...routesArray) {
        return routesArray.reduce((acc, curr) => acc.concat(curr.map(route => route.path)), []);
    }

    getJustComponents(...routesArray) {
        return routesArray.reduce((acc, curr) => acc.concat(curr.map(route => route.component)), []);
    }


    setAllRoutes(name, routes) {
        this.allRoutes.push({
            name: name,
            routes: routes 
        });
    }
    
    getAllRoutes() {
        return this.allRoutes;
    }
    
    getRoutesByName(name) {
        return this.allRoutes.find(routeGroup => routeGroup.name === name)?.routes;
    }
    
}
