import { hasData } from "../utils/data";

export default class Authorization {
    constructor(rolesApp, tokenName, location) {
        this.location = location;
        this.rolesApp = rolesApp;
        this.tokenName = tokenName;
        this.types = [];
        this.rolAuthorize = null;
        this.redirection = null;
        this.routesPublics = [];
    }

    setType(typeRol, routes, redirection) {
        if (typeRol === "default") {
            this.types.forEach(type => {
                type.routes = type.routes.filter(existingRoute => !routes.includes(this.normalizeRoute(existingRoute)));
            });
        }
        this.types.push({
            rol: typeRol,
            routes: routes.map(route => this.normalizeRoute(route)),
            redirection: this.normalizeRoute(redirection)
        });
    }


    setRoutesPublics(routes) {
        this.routesPublics = routes;
    }

    setRedirection(redirection) {
        this.redirection = redirection;
    }

    setRolAuthorize(rol) {
        this.rolAuthorize = rol;
    }

    page() {
        return this.normalizeRoute(this.location);
    }

    normalizeRoute(route) {
        return route.endsWith("/") ? route : route + "/";
    }

    redirectTo(route) {
        if (route.endsWith("/")) {
            route = route.slice(0, -1); // Eliminar la barra inclinada al final de la ruta
        }
        window.location.href = route;
    }

    getDataByRolName(nameRol) {
        return this.types.find(type => type.rol === nameRol);
    }

    run() {
        this.rolesApp.getJustNameRoles().forEach(rolName => {
            this.setType(
                this.rolesApp.get(rolName).name,
                this.rolesApp.get(rolName).pages,
                this.rolesApp.get(rolName).defaultRedirection);
        });
        if (!hasData(localStorage.getItem(this.tokenName))) {
            this.setRolAuthorize("default");
        }

        /* let notFound = true;
        console.log(this.types);
        this.types.forEach(type => {
            if (type.rol === this.rolAuthorize) {
                notFound = false;
                let isIn = false;
                type.routes.forEach(route => {
                    if (this.page().endsWith(route)) {
                        isIn = true;
                    }
                });
                if (!isIn) {
                    this.redirectTo(type.redirection);
                }
            }
        }); */

        const currentPage = this.page();
        let notFound = true;

        this.types.forEach(type => {
            if (type.rol === this.rolAuthorize) {
                notFound = false;
                const currentSegments = currentPage.split('/').filter(segment => segment !== ''); // Dividir la ruta en segmentos y eliminar los segmentos vacíos
                if (type.rol !== "default") currentSegments.shift();
                let isIn = false;
                type.routes.forEach(route => {
                    const routeSegments = route.split('/').filter(segment => segment !== ''); // Dividir la ruta de autorización en segmentos y eliminar los segmentos vacíos
                    let matches = true;
                    for (let i = 0; i < routeSegments.length; i++) {
                        if (routeSegments[i].startsWith(':')) { // Ignorar los segmentos que son parámetros
                            continue;
                        }
                        if (routeSegments[i] !== currentSegments[i]) {
                            matches = false;
                            break;
                        }
                    }

                    if (matches) {
                        isIn = true;
                    }
                });

                if (!isIn) {
                    this.redirectTo(type.redirection);
                }
            }
        });

        //comprobar si el rol no es reconocido
        if (notFound) {
            //significa que no entro nunca a un rol conocido
            // por lo tanto se tiene que redirecionar una vez a la redirecion del
            // rol por default, claro, se tiene que comprobar que el usuario ya este ahi
            // para no hacer un bucle de redirecciones
            if (!this.page().endsWith(this.getDataByRolName("default").redirection)) {
                this.redirectTo(this.getDataByRolName("default").redirection);
            }

        }
    }

    initializeRoles() {
        this.rolesApp.getJustNameRoles().forEach(rolName => {
            const { name, pages, defaultRedirection } = this.rolesApp.get(rolName);
            this.setType(name, pages, defaultRedirection);
        });
    }

}