import { lazy } from 'react';
import Router from '../core/router/app';


const Welcome = lazy(() => import('../pages/protected/Welcome'));
const Bills = lazy(() => import('../pages/protected/Bills'));
const ProfileSettings = lazy(() => import('../pages/protected/ProfileSettings'));
const CompanySettings = lazy(() => import('../pages/protected/CompanySettings'));
const Libros = lazy(() => import('../pages/protected/books'));
const Annexes = lazy(() => import('../pages/protected/Annexes'));

const router = new Router();


/* 
    Estas rutas solamente son parte de la template, se dejaran aca en el
    caso que se necesiten ver alguna forma de como hacer algo, de lo contrario
    todo esto es inutil
        

    const Dashboard = lazy(() => import('../pages/protected/Dashboard'));
    const Blank = lazy(() => import('../pages/protected/Blank'));
    const Charts = lazy(() => import('../pages/protected/Charts'));
    const Leads = lazy(() => import('../pages/protected/Leads'));
    const Integration = lazy(() => import('../pages/protected/Integration'));
    const Calendar = lazy(() => import('../pages/protected/Calendar'));
    const Team = lazy(() => import('../pages/protected/Team'));
    const Transactions = lazy(() => import('../pages/protected/Transactions'));
    const GettingStarted = lazy(() => import('../pages/GettingStarted'));
    const DocFeatures = lazy(() => import('../pages/DocFeatures'));
    const DocComponents = lazy(() => import('../pages/DocComponents'));


    router.setRoute('/dashboard', Dashboard);
    router.setRoute('/leads', Leads);
    router.setRoute('/settings-team', Team);
    router.setRoute('/calendar', Calendar);
    router.setRoute('/transactions', Transactions);
    router.setRoute('/getting-started', GettingStarted);
    router.setRoute('/features', DocFeatures);
    router.setRoute('/components', DocComponents);
    router.setRoute('/integration', Integration);
    router.setRoute('/charts', Charts);
    router.setRoute('/blank', Blank);
*/

router.setRoute('/welcome', Welcome);
router.setRoute('/settings-profile', ProfileSettings);
router.setRoute('/settings-company', CompanySettings);
router.setRoute('/bill', Bills);
router.setRoute('/libros', Libros);
router.setRoute('/annexes', Annexes);

export default router.build();
